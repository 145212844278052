  // title: 65-75, keywords near start
  // title: '#################################################################-----'
  // description: upto 160
  // description: `Learn Japanese playing Katakana and Hiragana kana games online. Starting with a game to learn Hiragana and Katakana kanas, we're regularly adding more games.`,

const URL = 'https://drlingua.com'

// title 65-75
const meta = {
  'default': {
    canonical: URL,
    shortlink: URL,
    title: 'Learn Japanese Game - Hiragana Katakana practice games - Dr Lingua',
    description: `Learn Japanese alphabets playing Katakana and Hiragana games online. Starting with games to learn Hiragana and Katakana, we're regularly adding more games.`,
    imageFB: `${URL}/assets/images/dr-lingua-fb-share.jpg`,
    imageTW: `${URL}/assets/images/dr-lingua-twitter-share.jpg`,
  },
  'kana-bento': {
    canonical: `${URL}/japanese/games/kana-bento/`,
    shortlink: 'https://goo.gl/hDCWD8',
    title: 'Hiragana Drag and Drop or Katakana Drag and Drop - Dr Lingua',
    description: `Free to play, mobile friendly, Hiragana Drag and Drop or Katakana Drag and Drop exercise game. Learn Hiragana and Katakana kanas playing games.`,
    imageFB: `${URL}/assets/images/kana-bento-screen.jpg`,
    imageTW: `${URL}/assets/images/kana-bento-screen.jpg`,
  },
  'hiragana-kana-bento': {
    canonical: `${URL}/japanese/games/kana-bento/`,
    shortlink: 'https://goo.gl/2RWmFu',
    title: 'Drag and Drop Hiragana game. Mobile friendly. Kana Bento - Dr Lingua',
    description: `Kana Bento Hiragana Drag and Drop version. Learn Hiragana kanas with drag-n-drop. Kana Bento is free and mobile friendly, Hiragana kana game.`,
    imageFB: `${URL}/assets/images/kana-bento-hiragana-drag-drop.jpg`,
    imageTW: `${URL}/assets/images/kana-bento-hiragana-drag-drop.jpg`,
  },
  'katakana-kana-bento': {
    canonical: `${URL}/japanese/games/kana-bento/`,
    shortlink: 'https://goo.gl/ezMtWq',
    title: 'Drag and Drop Katakana game. Mobile friendly. Kana Bento - Dr Lingua',
    description: `Kana Bento Katakana Drag and Drop version. Learn Katakana kanas with drag-n-drop. Kana Bento is free and mobile friendly, Katakana kana game.`,
    imageFB: `${URL}/assets/images/kana-bento-katakana-drag-drop.jpg`,
    imageTW: `${URL}/assets/images/kana-bento-katakana-drag-drop.jpg`,
  },
  'kana-grid': {
    canonical: `${URL}/japanese/games/kana-grid/`,
    shortlink: 'https://goo.gl/piVW3B',
    title: 'Kana Grid - Improve Japanese Hiragana and Katakana game - Dr Lingua',
    description: `Kids can familiarise themselves with diffeent Hiragana and Katakana kana playing with Kana Grid. iPad / tablet / computer friendly.`,
    imageFB: `${URL}/assets/images/kana-grid-hiragana-game-romaji.jpg`,
    imageTW: `${URL}/assets/images/kana-grid-hiragana-game-romaji.jpg`,
  },

  // Blog
  'blog': {
    canonical: `${URL}/blog`,
    title: `Updates on Japanese learning games updates and new stuff - Dr Lingua`,
    description: `Dr Lingua makes Japanese language games for kids. We've started with Japanese alphabets, Hiragana and Katakana. Stay up to date here as we add new games.`,
  },
  'site-origins': {
    canonical: `${URL}/blog/site-origins`,
    shortlink: 'https://goo.gl/d2GyPz',
    title: `Why I'm making kids Japanese language learning games - Dr Lingua`,
    subtitle: `Why I started making educational Japanese language games for kids`,
    description: `I'm a HTML5 game developer making kids Japanese language games to keep my kids enthusiastic about learning Japanese.`,
    thumb: `/assets/images/blog/site-origins-thumb.jpg`,
  },
  'tech-stack': {
    canonical: `${URL}/blog/tech-stack`,
    shortlink: 'https://goo.gl/AytU6N',
    title: `Phaser, React, Gatsby, Firebase, and more... Technology - Dr Lingua`,
    subtitle: `The web technologies behind Dr Lingua...`,
    description: `A breakdown on the technology used to build and host the Japanese HTML5 language games on Dr Lingua`,
    thumb: `/assets/images/blog/technology-stack-thumb.jpg`,
  },
  'educational-games': {
    canonical: `${URL}/blog/html5-japanese-educational-games`,
    shortlink: 'https://goo.gl/AHS4i1',
    title: `Making HTML5 Japanese educational games without Flash - Dr Lingua`,
    subtitle: `Flash is dead, so how do we make kids education games now? (hint, it's HTML5)`,
    description: `Making educational games for kids means making games that run on mobile devices. Flash has long passed it's use by date for making online educational content.`,
    thumb: `/assets/images/blog/html5-kids-japanese-educational-games-thumb.jpg`,
  },
  'hiragana-games-online': {
    canonical: `${URL}/blog/hiragana-games-online`,
    shortlink: 'https://goo.gl/fq9sy3',
    title: `Hiragana games online resources, finding good Hiragana Games - Dr Lingua`,
    subtitle: `Finding good Hiragana practice games online`,
    description: `There are not many mobile friendly Hiragana games you can play online. I've compiled a list of Hiragana practice games online you can play on a mobile.`,
    thumb: `/assets/images/blog/hiragana-games-online-thumb.jpg`,
  },
  'kano-bento-hiragana-katakana': {
    canonical: `${URL}/blog/kano-bento-hiragana-katakana`,
    shortlink: 'https://goo.gl/WUq4Gi',
    title: `Drag-n-drop Kana Bento split into Hiragana, and Katakana - Dr Lingua`,
    subtitle: `Custom versions of Kana Beno opening into Hiragana or Katakana`,
    description: `To help teachers and parents, I've added versions of Kana Bento kana drag-n-drop to open straight into Hiragana or Katakana`,
    thumb: `/assets/images/blog/drag-drop-kana-bento-thumb.jpg`,
  },
  'took-on-a-sponsor': {
    canonical: `${URL}/blog/took-on-a-sponsor`,
    shortlink: 'https://goo.gl/WUq4Gi',
    title: `I've taken on sponsors for Dr Lingua`,
    subtitle: `I've taken on sponsors for Dr Lingua`,
    description: `To help teachers and parents, I've added versions of Kana Bento kana drag-n-drop to open straight into Hiragana or Katakana`,
    thumb: `/assets/images/blog/site-origins-thumb.jpg`,
  },

  // Utility pages
  'apps': {
    canonical: `${URL}/apps/`,
    title: `Japanese learning apps - Dr Lingua`,
    description: `Dr Lingua Japanese learning apps`,
  },
  'support': {
    canonical: `${URL}/support/`,
    title: `Japanese app support - Dr Lingua`,
    description: `Dr Lingua Japanese app support`,
  },
  'terms': {
    canonical: `${URL}/terms/`,
    title: `Terms of Service - Dr Lingua`,
    description: `Dr Lingua Terms of Service`,
  },
  'privacy': {
    canonical: `${URL}/privacy/`,
    title: `Privacy Policy - Dr Lingua`,
    description: `Dr Lingua Privacy Policy`,
  },
  'contact': {
    canonical: `${URL}/contact/`,
    title: `Contact - Dr Lingua`,
    description: `Contact Dr Lingua through social media, or follow for updates.`,
  },
  '404': {
    canonical: `${URL}/404/`,
    title: `Oops, wrong URL!`,
    description: `Looks like you're lost on Dr Lingua.`,
  },
}

export default meta
